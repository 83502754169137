import * as React from 'react';
import Switch from '@mui/material/Switch';
import {FormControlLabel} from "@mui/material";

import './Switcher.scss'

export default function Switcher({checked, setChecked, label = ''}) {

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <FormControlLabel
            control={
                <Switch checked={checked} onChange={handleChange} name={label}/>
            }
            label={label}
        />
    );
}