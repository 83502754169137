import React from "react";
import { useNavigate } from "react-router";

function NotFound() {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate("/");
    }, [navigate]);

    return (
        <div>
            <h1>404 - Сторінку не знайдено</h1>
        </div>
    );
}

export default NotFound;
