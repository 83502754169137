import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { UALProvider } from "ual-reactjs-renderer";
import {waxChain, waxAuthenticators} from './config/wax.config'
import { Provider } from 'react-redux';
import { createStore } from 'redux';


import './index.scss'
import 'react-toastify/dist/ReactToastify.css';


const defaultState = {
    allCollections: [],
    allUserCollections: [],
}

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case "ALL_COLLECTIONS":
            return {...state, allCollections: action.payload}

        case "ALL_USER_COLLECTIONS":
            return {...state, allUserCollections: action.payload}

        default:
            return state;
    }
}

const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <UALProvider
            chains={[waxChain]}
            authenticators={waxAuthenticators}
            appName={'AlchemySwap'}
        >
            <Router>
                <App />
            </Router>
        </UALProvider>
    </Provider>,
    document.getElementById('root')
);
