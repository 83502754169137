import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function SliderS({ amount, slideValue, setSliderValue }) {


    return (
        <Box width={175} height={25}>
            <Slider value={slideValue} min={0} max={amount} marks onChange={(event, newValue) => {
                setSliderValue(newValue);
            }} />
        </Box>
    );
}
